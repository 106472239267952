<tec-detail-layout [title]="'PROFILE.MY_PROFILE' | translate">
	<ng-container id="section-left">
		<tec-tile type="column" icon="icon-user" [title]="'PROFILE.MY_PROFILE' | translate">
			<ng-container actions *ngIf="isAdmin">
				<tec-link [label]="'GENERAL.EDIT' | translate" (click)="editProfile()"></tec-link>
			</ng-container>
			<ng-container>
				<div class="tile__body__column">
					<tec-avatar
						[avatar]="{ id: '64', img: image, name: nameAvatar }"
						[size]="'lg'"
						[buttonChange]="true"
						(changeImage)="changeImage($event)"
					>
						<tec-link [label]="'GENERAL.REMOVE' | translate" (click)="removeAvatar(1)"></tec-link>
					</tec-avatar>
					<form [formGroup]="imageForm">
						<tec-upload
							style="display: none"
							class="upload__image"
							[size]="'default'"
							[formControlName]="'avatar'"
							[canBeRemoved]="true"
							[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
							[tagImageStatus]="'default'"
							(fileSelected)="fileSelected($event, 1)"
						></tec-upload>
					</form>
				</div>
				<div class="tile__body__column">
					<div class="grid grid-i2">
						<div class="info-group">
							<b>{{ 'PROFILE.NAME' | translate }}</b>
							<span>{{ name }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.SURNAME' | translate }}</b>
							<span>{{ surname }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.EMAIL' | translate }}</b>
							<span><tec-icon [name]="'icon-mail'" [size]="'xs'"></tec-icon>{{ email }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.PASSWORD' | translate }}</b>
							<tec-link [label]="'PROFILE.CHANGE' | translate" (click)="showPasswordChange()"></tec-link>
							<!-- <span *ngIf="showPassword">{{ password }} </span> -->
						</div>
					</div>
				</div>
			</ng-container>
		</tec-tile>
		<tec-tile type="default" icon="icon-info" styling="default" [title]="'GENERAL.INFO' | translate">
			<div class="grid grid-i2">
				<div *ngIf="!isAdmin; else showPartner" class="info-group">
					<b>{{ 'PROFILE.POSITION' | translate }}</b>
					<span>{{ charge || 'N/D' }} </span>
				</div>
				<ng-template #showPartner>
					<div class="info-group">
						<b>{{ 'PROFILE.POSITION_ADMIN' | translate }}</b>
						<tec-badge [label]="role" [type]="'square'"></tec-badge>
					</div>
				</ng-template>

				<div class="info-group">
					<b>{{ 'PROFILE.PHONE' | translate }}</b>
					<span><tec-icon [name]="'icon-phone'" [size]="'xs'"></tec-icon>{{ phone }} </span>
				</div>
			</div>
		</tec-tile>
	</ng-container>
	<ng-container id="section-right">
		<tec-tile type="default" icon="icon-store" styling="default" [title]="'PROFILE.MY_STORE' | translate">
			<div class="grid grid-i2">
				<div class="info-group">
					<b>{{ 'PROFILE.COMERCIAL_NAME' | translate }}</b>
					<span>{{ brandName }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.CORPORATE_NAME' | translate }}</b>
					<span>{{ businessName }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.CIF' | translate }}</b>
					<span>{{ cif }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.BILLING_EMAIL' | translate }}</b>
					<span><tec-icon [name]="'icon-mail'" [size]="'xs'"></tec-icon>{{ billingEmail }} </span>
				</div>
			</div>
		</tec-tile>
	</ng-container>
</tec-detail-layout>

<!-- Modal de edición del perfil -->
<tec-modal
	[isVisible]="isVisibleModalEdit"
	[title]="'PROFILE.MY_PROFILE' | translate"
	[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
	[buttonPrimaryLabel]="'GENERAL.SAVE' | translate"
	(closeEvent)="closeDialogModalEdit($event)"
>
	<tec-tile [type]="'column'">
		<ng-container body>
			<div class="tile__body__column">
				<tec-avatar
					[avatar]="{ id: '64', img: image2, name: nameAvatar }"
					[size]="'lg'"
					[buttonChange]="true"
					(changeImage)="changeImage($event)"
				>
					<tec-link [label]="'GENERAL.REMOVE' | translate" (click)="removeAvatar(2)"></tec-link>
				</tec-avatar>
				<form [formGroup]="imageForm">
					<tec-upload
						style="display: none"
						formControlName="avatar"
						class="upload__image"
						[size]="'default'"
						[formControlName]="'avatar'"
						[canBeRemoved]="true"
						[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
						[tagImageStatus]="'default'"
						(fileSelected)="fileSelected($event, 2)"
					></tec-upload>
				</form>
			</div>
			<form [formGroup]="editForm">
				<div class="tile__body__column">
					<div class="grid grid-i2">
						<div class="info-group">
							<b>{{ 'PROFILE.NAME' | translate }}</b>
							<tec-input [value]="name" [type]="'text'" formControlName="name"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.SURNAME' | translate }}</b>
							<tec-input [value]="surname" [type]="'text'" formControlName="surname"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.EMAIL' | translate }}</b>
							<tec-input [value]="email" [type]="'email'" formControlName="email"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.PASSWORD' | translate }}</b>
							<tec-link
								[label]="'PROFILE.CHANGE_PASSWORD' | translate"
								(click)="showPasswordChangeModal()"
								*ngIf="!showPasswordModal"
							></tec-link>
							<tec-input
								*ngIf="showPasswordModal"
								[value]="password"
								[type]="'password'"
								formControlName="password"
							></tec-input>
						</div>
					</div>
				</div>
			</form>
		</ng-container>
	</tec-tile>
</tec-modal>

<!-- Modal de cambio de contraseña -->
<tec-modal
	[isVisible]="isVisibleModalChangePassword"
	[title]="'CHANGE_PASS.TITLE' | translate"
	[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
	[buttonPrimaryLabel]="'GENERAL.SAVE' | translate"
	(closeEvent)="closeDialogModalChangePassword($event)"
>
	<ng-container body>
		<span>
			{{ 'CHANGE_PASS.DESCRIPTION' | translate }}
		</span>
		<form [formGroup]="form">
			<tec-form-group [label]="'CHANGE_PASS.CURRENT_PASSWORD' | translate">
				<tec-input
					type="password"
					formControlName="currentPass"
					[placeholder]="'CHANGE_PASS.PLACEHOLDERS.CURRENT_PASSWORD' | translate"
					[ngClass]="{
						'input--error': form.get('currentPass')?.invalid && form.get('currentPass')?.touched
					}"
					[hasError]="form.get('currentPass')?.invalid && form.get('currentPass')?.touched"
				></tec-input>
				<div class="form-group__error-message mb-2" *ngIf="form.get('currentPass')?.invalid && form.get('currentPass')?.touched">
					<small>La contraseña es obligatoria.</small>
				</div>
			</tec-form-group>
			<tec-form-group
				[label]="'CHANGE_PASS.NEW_PASSWORD' | translate"
				[description]="'CHANGE_PASS.CONDITIONS' | translate"
			>
				<tec-input
					type="password"
					formControlName="newPass"
					[placeholder]="'CHANGE_PASS.PLACEHOLDERS.NEW_PASSWORD' | translate"
					[ngClass]="{
						'input--error': form.get('newPass')?.invalid && form.get('newPass')?.touched
					}"
					[hasError]="form.get('newPass')?.invalid && form.get('newPass')?.touched"
					(input)="newPassword?.updateValueAndValidity()"
				></tec-input>
				<div *ngIf="form.get('newPass')?.invalid && form.get('newPass')?.touched" class="mb-2 form-group__error-message">
					<small>La nueva contraseña es obligatoria.</small>
				</div>
			</tec-form-group>
			<tec-form-group [label]="'CHANGE_PASS.CONFIRM_PASSWORD' | translate">
				<tec-input
					type="password"
					formControlName="confirmNewPass"
					[placeholder]="'CHANGE_PASS.PLACEHOLDERS.CONFIRM_PASSWORD' | translate"
					[ngClass]="{
						'input--error': form.get('confirmNewPass')?.invalid && form.get('confirmNewPass')?.touched
					}"
					[hasError]="form.get('confirmNewPass')?.invalid && form.get('confirmNewPass')?.touched"
				></tec-input>
				<div *ngIf="form.get('confirmNewPass')?.invalid && form.get('confirmNewPass')?.touched" class="mb-2 form-group__error-message">
					<small>La confirmación de nueva contraseña es obligatoria.</small>
				</div>
			</tec-form-group>
		</form>
	</ng-container>
</tec-modal>

<!-- Modal OK cambio contraseña -->
<tec-modal
	type="confirmation"
	[isVisible]="isVisibleModalOKChange"
	buttonPrimaryLabel="Aceptar"
	(closeEvent)="closeDialogModalOKChange($event)"
>
	<ng-container body>
		<tec-tag-icon [size]="'default'" [icon]="'icon-check'"></tec-tag-icon>
		<div class="body__text">
			<h4>La contraseña ha sido actualizada correctamente.</h4>
		</div>
	</ng-container>
</tec-modal>
