<tec-list-layout *ngIf="dashBoard$ | async as dashBoard; else spinner">
	<tec-title-group [title]="'COMMISSIONS.NAME' | translate" [tooltip]="'Tooltip'"></tec-title-group>
	<ng-container content>
		<tec-toolbar
			*ngIf="adminRole === 'super_admin'"
			[toolbar]="{
				button: { label: 'PARTNERS.GENERATE_REPORT' | translate },
				filters: [
					{
						type: 'select',
						name: 'partnerSelected',
						title: 'PARTNERS.ALL_PARTNERS' | translate,
						placeholder: 'PARTNERS.ALL_PARTNERS' | translate,
						search: true,
						options: filterPartners
					}
				],
				disabled: false
			}"
		></tec-toolbar>
		<div class="grid grid-e3">
			<div class="template-list__content__section">
				<tec-stats
					[title]="'DASHBOARD.GENERATE_THIS_MONTH' | translate"
					[value]="commissions.total"
					[size]="'sm'"
					[ghost]="true"
					[tagRounded]="{ icon: 'icon-trending-up', label: '5%' }"
				></tec-stats>
				<tec-stats
					[title]="'DASHBOARD.OUTSTANDING_RECEIVABLES' | translate"
					[value]="commissions.pending"
					[size]="'sm'"
				></tec-stats>
			</div>
			<div class="template-list__content__section">
				<div class="grid grid-e2">
					<tec-stats
						[title]="'COMMISSIONS.SIGN' | translate"
						[value]="typeOfProducts.Signature.total"
						[size]="'sm'"
					></tec-stats>
					<tec-stats
						[title]="'COMMISSIONS.IDENTITY' | translate"
						[value]="typeOfProducts.Identity.total"
						[size]="'sm'"
					></tec-stats>
					<tec-stats
						[title]="'COMMISSIONS.CUSTOMER_HUB' | translate"
						[value]="typeOfProducts.Customer_hub.total"
						[size]="'sm'"
					></tec-stats>
					<tec-stats
						[title]="'COMMISSIONS.OTHERS' | translate"
						[value]="typeOfProducts.Others.total"
						[size]="'sm'"
					></tec-stats>
				</div>
			</div>
			<div class="template-list__content__section">
				<tec-tile type="default" styling="default" [title]="'COMMISSIONS.EVOLUTION' | translate">
					<tec-chart
						[style]="{}"
						[initOptions]="{}"
						[options]="{
							tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
							yAxis: { type: 'category', data: monthsEvolutionData },
							xAxis: { type: 'value' },
							series: chartDataSeries
						}"
					></tec-chart>
				</tec-tile>
			</div>
		</div>
	</ng-container>

	<!-- Tabla clientes partner -->
	<ng-container content *ngIf="adminRole === 'partner'">
		<div class="template-list__content__section template-list__content__section--no-gap">
			<tec-title-group [title]="'COMMISSIONS.MY_CLIENTS' | translate"></tec-title-group>
			<tec-toolbar
				[toolbar]="{
					search: { name: 'name', placeholder: 'GENERAL.FIND' | translate },
					filters: filtersClients,
					disabled: false
				}"
				tooltip="'tooltip'"
				class="selected-elements"
				(formFilter)="getFilters($event, 'created')"
				(actionClick)="settlementRequestAction($event)"
			></tec-toolbar>
			<tec-table
				[headers]="tableHeadersClients"
				[rows]="tableRowsClients"
				[config]="{
					clickeable: true,
					checkbox: false,
					loading: false,
					pagination: pagination,
				}"
				(rowEvent)="tableRowClick($event)"
				(paginationChange)="paginationChange($event)"
			></tec-table>
		</div>
	</ng-container>

	<!-- Tablas liquidacion y expendientes partner_admin -->
	<ng-container content *ngIf="adminRole === 'partner_admin'">
		<div class="template-list__content__section template-list__content__section--no-gap">
			<tec-tabs
				[type]="'default'"
				[tabs]="[
					{ id: '1', label: 'COMMISSIONS.SETTLEMENT_REQUESTS' | translate, selected: true },
					{ id: '2', label: 'COMMISSIONS.PROCEEDINGS' | translate, notification: 5 }
				]"
				(tabSelected)="tabSelected($event)"
			>
			</tec-tabs>
			<div *ngIf="tab === 1">
				<tec-toolbar
					[toolbar]="{
						search: { name: 'name', placeholder: 'GENERAL.FIND' | translate },
						filters: filtersSettlementRequest,
						disabled: false
					}"
					tooltip="'tooltip'"
					class="selected-elements"
					(formFilter)="getFilters($event, 'created')"
				></tec-toolbar>
				<tec-table
					[headers]="tableHeadersSettlementRequest"
					[rows]="tableRowsSettlementRequest"
					[config]="{
					clickeable: true,
					checkbox: false,
					loading: false,
					pagination: pagination,
				}"
					(cellClick)="cellClickSelection($event)"
					(rowEvent)="rowClickSelection($event)"
					(paginationChange)="paginationChange($event)"
				></tec-table>
			</div>
			<div *ngIf="tab === 2">
				<tec-toolbar
					[toolbar]="{
						search: { name: 'name', placeholder: 'GENERAL.FIND' | translate },
						button: {
							label: 'COMMISSIONS.PROCEEDINGS_TABLE.REQUEST_SETTLEMENT' | translate,
							disabled: proceedingsSelected === 0 ? true : false
						},
						filters: filtersProceedings,
						disabled: false
					}"
					tooltip="'tooltip'"
					class="selected-elements"
					(formFilter)="getFilters($event, 'created')"
					(actionClick)="settlementRequestAction($event)"
				></tec-toolbar>
				<tec-table
					[headers]="tableHeadersProceedings"
					[rows]="tableRowsProceedings"
					[config]="{
					clickeable: true,
					checkbox: true,
					loading: false,
					pagination: pagination,
				}"
					(rowEvent)="tableRowClick($event)"
					(paginationChange)="paginationChange($event)"
					(selectionChange)="test($event)"
				></tec-table>
			</div>
		</div>
	</ng-container>

	<!-- Tabla liquidaciones super_admin -->
	<ng-container content *ngIf="adminRole === 'super_admin'">
		<div class="template-list__content__section">
			<tec-title-group [title]="'COMMISSIONS.SETTLEMENTS' | translate"></tec-title-group>
			<tec-toolbar
				[toolbar]="{
					search: { name: 'name', placeholder: 'GENERAL.FIND' | translate },
					filters: filtersSettlement,
					disabled: false
				}"
				tooltip="'tooltip'"
				class="selected-elements"
				(formFilter)="getFilters($event, 'created')"
			></tec-toolbar>
			<tec-table
				[headers]="tableHeadersSettlements"
				[rows]="tableRowsSettlements"
				[config]="{
					clickeable: true,
					checkbox: false,
					loading: false,
					pagination: pagination,
				}"
				(cellClick)="cellClickSelection($event)"
				(paginationChange)="paginationChange($event)"
			></tec-table>
		</div>
	</ng-container>
</tec-list-layout>
<ng-template #spinner>
	<tec-spinner [show]="true" [opaque]="true" [watermark]="true"></tec-spinner>
</ng-template>

<!-- MODAL DE EDICION DE FILA -->
<ng-container>
	<tec-modal
		[title]="'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.EDIT_SETTLEMENT_REQUEST' | translate"
		[isVisible]="isVisibleDialogEdit"
		[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
		[buttonPrimaryLabel]="'GENERAL.SAVE' | translate"
		(closeEvent)="closeDialogModalEdit($event)"
	>
		<ng-container body>
			<div class="layout--50-50">
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.REQUEST_NUMBER' | translate }}</b>
					<span>{{ dataEditModalSettlementRequest.cells.length > 0 ? dataEditModalSettlementRequest.cells[0].label : '123456789'  }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.INVOICE_DELIVERY' | translate }}</b>
					<tec-toggle
						[options]="[
							{ id: 'pending', label: 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.PENDING' | translate },
							{ id: 'sent', label: 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.SENT' | translate }
						]"
						(selectedOptionChange)="selectedOptionChange($event)"
						(valueChange)="selectedOptionChange($event)"
					></tec-toggle>
				</div>
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.PROCEEDINGS_NUMBER' | translate }}</b>
					<tec-badge [label]="dataEditModalSettlementRequest.cells.length > 0 ? dataEditModalSettlementRequest.cells[2].label : '9'" [type]="'square'" [size]="'lg'"></tec-badge>
				</div>
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.BILLING_PERIOD' | translate }}</b>
					<span>{{ dataEditModalSettlementRequest.cells.length > 0 ? dataEditModalSettlementRequest.cells[3].label : 'Enero de 2024' }}</span>
				</div>
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.IMPORT' | translate }}</b>
					<span>{{ dataEditModalSettlementRequest.cells.length > 0 ? dataEditModalSettlementRequest.cells[4].label : '521,45€' }}</span>
				</div>
				<div class="info-group">
					<b>{{ 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.REQUEST_DATE' | translate }}</b>
					<span>{{ dataEditModalSettlementRequest.cells.length > 0 ? dataEditModalSettlementRequest.cells[5].label : '15/01/2024' }}</span>
				</div>
			</div>
		</ng-container>
	</tec-modal>
</ng-container>

<!-- MODAL DE ELIMINACION DE FILA -->
<ng-container>
	<tec-modal
		[isVisible]="isVisibleDialogRemove"
		type="confirmation"
		[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
		[buttonPrimaryLabel]="'GENERAL.REMOVE' | translate"
		[buttonPrimaryStyle]="true"
		(closeEvent)="closeDialogModalDelete($event)"
	>
		<ng-container body>
			<tec-tag-icon [size]="'default'" [icon]="'icon-alert'"></tec-tag-icon>
			<div class="body__text">
				<h4 [innerHTML]="'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.DELETE_MESSAGE' | translate"></h4>
				<span [innerHTML]="'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.DELETE_WARNING' | translate"></span>
			</div>
		</ng-container>
	</tec-modal>
</ng-container>

<!-- MODAL DE CONFIRMACIÓN DE SOLICITAR LIQUIDACION -->
<ng-container>
	<tec-modal
		[isVisible]="isVisibleDialogDownload"
		type="confirmation"
		[buttonPrimaryLabel]="'COMMISSIONS.PROCEEDINGS_TABLE.DOWNLOAD_PDF' | translate"
		(closeEvent)="closeDialogModalDownload($event)"
	>
		<ng-container body>
			<tec-tag-icon [size]="'default'" [icon]="'icon-check'" [status]="'success'"></tec-tag-icon>
			<div class="body__text">
				<h4 [innerHTML]="'COMMISSIONS.PROCEEDINGS_TABLE.RESULT_SUCCESS' | translate"></h4>
				<span [innerHTML]="'COMMISSIONS.PROCEEDINGS_TABLE.SEND_BILLING' | translate"></span>
			</div>
		</ng-container>
	</tec-modal>
</ng-container>
