import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
	AvatarComponent,
	BaseComponent,
	ButtonIconComponent,
	FooterComponent,
	MenuComponent,
	SelectComponent,
	TopnavComponent,
	WatermarkComponent,
} from '@tecalisdev/infinity';
import { Observable, catchError, filter, forkJoin, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/authentication/guards/auth.guard';
import { SSOAuth } from './core/authentication/guards/ssoAuth.guard';
import { HeaderInterceptor } from './core/services/header.interceptor';
import { ServerErrorInterceptor } from './core/services/server-error.interceptor';
import { userReducer } from './core/store/user/user.reducer';
import { CommissionsModule } from './features/commissions/commissions.module';
import { HomeModule } from './features/home/home.module';
import { ProfileUserModule } from './features/profile/profile.module';
import { PasswordRecoverComponent } from './layout/password-recover/password-recover.component';
import { MasterService } from './shared/services/master.service';
import { SpinnerService } from './shared/services/spinner.service';
import { SharedModule } from './shared/shared.module';
import { MasterEffects } from './shared/stores/master/master.effect';
import { masterReducer } from './shared/stores/master/master.reducer';
import { SalesModule } from './features/sales/sales.module';

class MultiHttpLoader implements TranslateLoader {
	constructor(private http: HttpClient, public resources: string[]) {}

	getTranslation(lang: string): Observable<any> {
		const requests = this.resources.map((path) => this.http.get(`${path}${lang}.json`).pipe(catchError(() => of(null))));

		return forkJoin(requests).pipe(
			filter((value) => {
				return !!value.filter((item) => !!item);
			}),
			map((response) => Object.assign({}, ...response))
		);
	}
}

registerLocaleData(localeEs);
export function HttpLoaderFactory(http: HttpClient) {
	return new MultiHttpLoader(http, ['assets/i18n/', '/i18n/']);
}

@NgModule({
	declarations: [AppComponent, PasswordRecoverComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		CommonModule,
		SharedModule,
		HomeModule,
		ProfileUserModule,
		CommissionsModule,
		MatNativeDateModule,
		BrowserAnimationsModule,
		BaseComponent,
		TopnavComponent,
		FooterComponent,
		WatermarkComponent,
		SelectComponent,
		ButtonIconComponent,
		MenuComponent,
		AvatarComponent,
		StoreModule.forRoot({ masterState: masterReducer, userState: userReducer }),
		EffectsModule.forRoot([MasterEffects]),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
		MatDialogModule,
	],
	providers: [
		AuthGuard,
		{ provide: LOCALE_ID, useValue: 'es' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HeaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ServerErrorInterceptor,
			multi: true,
		},
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleClientId),
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(environment.googleClientId),
					},
				],
				onError: (err) => {
					console.error(err);
				},
			} as SocialAuthServiceConfig,
		},
		SpinnerService,
		MasterService,
		SSOAuth,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
