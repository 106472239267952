import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { Profile } from './models/profile.model';
import { ProfileUserService } from './services/profile.service';

const REGEX_PASS = /^(?=.*[A-Z])(?=.*\d)(?=.*[@_!#$%^&*()\-+=<>?])[A-Za-z\d@_!#$%^&*()\-+=<>?]{8,}$/;

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileUserComponent implements OnInit {
	// isAdmin: boolean = true;
	imageForm!: FormGroup;
	editForm!: FormGroup;
	form!: FormGroup;
	isAdmin: boolean =
		this.jwtService.decompileToken(this.cookieService.get('token'))?.data?.role === 'partner' ? false : true;
	role: string = this.jwtService.decompileToken(this.cookieService.get('token'))?.data?.role || '';
	nameAvatar: string = '';
	name: string = '';
	surname: string = '';
	email: string = '';
	password: string = '';
	charge: string = '';
	phone: string = '';
	image: string = '';
	image2: string = '';

	brandName: string = '';
	businessName: string = '';
	cif: string = '';
	billingEmail: string = '';

	isVisibleModalEdit: boolean = false;
	isVisibleModalChangePassword: boolean = false;
	isVisibleModalOKChange: boolean = false;
	showPasswordModal: boolean = false;
	showPassword: boolean = false;

	isAuthorized: boolean = false;

	constructor(
		private jwtService: JwtService,
		private readonly cookieService: CookieService,
		private profileService: ProfileUserService,
		private readonly change: ChangeDetectorRef,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private formBuilder: FormBuilder
	) {
		this.imageForm = this.formBuilder.group({
			avatar: [''],
		});

		this.editForm = this.formBuilder.group({
			avatar: [''],
			name: [''],
			surname: [''],
			email: [''],
			password: [''],
		});

		this.form = this.formBuilder.group({
			currentPass: ['', Validators.required],
			newPass: ['', [Validators.required, Validators.pattern(REGEX_PASS)]],
			confirmNewPass: ['', [Validators.required, Validators.pattern(REGEX_PASS)]],
		});
	}
	ngOnInit(): void {
		this.getProfileData();
	}

	getProfileData() {
		this.profileService.getProfileInfo().subscribe({
			next: (profile: Profile) => {
				this.name = profile.name || '';
				this.surname = profile.surname || '';

				this.nameAvatar = `${this.name} ${this.surname}`;

				this.email = profile.email || '';
				this.password = '*******************';
				this.charge = profile.position || '';
				this.phone = profile.phone || '';
				this.brandName = profile.my_company.comercial_name || '';
				this.businessName = profile.my_company.company_name || '';
				this.cif = profile.my_company.document_number || '';
				this.billingEmail = profile.my_company.email || '';
				this.image = this.image2 = profile.avatar || '';

				this.change.detectChanges();
			},
		});
	}

	changeImage($event: any) {
		const element = this.elementRef.nativeElement.querySelector('.upload__image > div > div > input');
		if (element) {
			this.renderer.selectRootElement(element).click();
		}
	}

	fileSelected(event: any, option: number) {
		const formData = new FormData();

		formData.append('name', this.name || 'Rafa');
		formData.append('surname', this.surname || 'Cruz');
		formData.append('email', this.email || 'prueba@tecalis.com');
		formData.append('password', this.password || 'prueba@tecalis.com');
		formData.append('role_id', '1');
		formData.append('avatar', event[0], event[0].name);

		this.profileService.setProfileInfo(formData).subscribe((result) => {
			this.profileService.getProfileInfo().subscribe((profile: Profile) => {
				switch (option) {
					case 1:
						this.image = this.image2 = profile.avatar || '';
						break;
					case 2:
						this.image2 = profile.avatar || '';
						break;
				}
				this.change.detectChanges();
			});
		});
	}

	closeDialogModalEdit(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				this.updateProfileData();
				this.isVisibleModalEdit = false;
				break;
			//Cancelar
			case 'buttonSecondary':
				this.isVisibleModalEdit = false;
				break;
			//Cruz
			default:
				this.isVisibleModalEdit = false;
		}
	}

	updateProfileData() {
		const formData = new FormData();
		let data = this.editForm.getRawValue();

		formData.append('name', data.name !== '' ? data.name : this.name);
		formData.append('surname', data.surname !== '' ? data.surname : this.surname);
		formData.append('email', data.email !== '' ? data.email : this.email);
		if (this.image2 === '') formData.append('delete_avatar', 'true');
		formData.append('role_id', '1');

		//renew_password --> para renovar password

		this.profileService.setProfileInfo(formData).subscribe((result) => {
			this.profileService.getProfileInfo().subscribe((profile: Profile) => {
				this.getProfileData();
			});
		});
	}

	editProfile() {
		this.isVisibleModalEdit = true;
	}

	removeAvatar(selection: number) {
		switch (selection) {
			case 1:
				const formData = new FormData();
				this.image = this.image2 = '';

				formData.append('name', this.name || 'Rafa');
				formData.append('surname', this.surname || 'Cruz');
				formData.append('email', this.email || 'prueba@tecalis.com');
				formData.append('role_id', '1');
				formData.append('delete_avatar', 'true');

				this.profileService.setProfileInfo(formData).subscribe((result) => {
					this.profileService.getProfileInfo().subscribe((profile: Profile) => {
						this.change.detectChanges();
					});
				});
				break;
			case 2:
				this.image2 = '';
				break;
		}
	}

	showPasswordChangeModal() {
		this.showPasswordModal = true;
	}

	showPasswordChange() {
		this.isVisibleModalChangePassword = true;
	}

	get currentPassword() {
		return this.form.get('currentPass');
	}

	get newPassword() {
		return this.form.get('newPass');
	}

	get confirmNewPassword() {
		return this.form.get('confirmNewPass');
	}

	closeDialogModalChangePassword(event: any) {
		const rawForm = this.form.getRawValue();

		switch (event) {
			//Guardar
			case 'buttonPrimary':
				if (
					rawForm &&
					rawForm.newPass === rawForm.confirmNewPass &&
					rawForm.newPass !== '' &&
					rawForm.confirmNewPass !== '' &&
					rawForm.currentPass !== rawForm.newPass &&
					rawForm.currentPass !== '' &&
					this.form.valid
				) {
					this.profileService.changePassword(rawForm.newPass).subscribe({
						next: () => {},
						error: () => {},
						complete: () => {
							this.form.reset();
							this.isVisibleModalChangePassword = false;
							this.isVisibleModalOKChange = true;
							this.change.detectChanges();
						},
					});
				} else {
					if (rawForm.currentPass === '' || this.currentPassword?.invalid) this.form.get('currentPass')?.markAsTouched();
					if (rawForm.newPass === '' || this.newPassword?.invalid) this.form.get('newPass')?.markAsTouched();
					if (rawForm.confirmNewPass === '' || this.confirmNewPassword?.invalid)
						this.form.get('confirmNewPass')?.markAsTouched();
				}
				break;
			//Cancelar
			case 'buttonSecondary':
				this.form.reset();
				this.isVisibleModalChangePassword = false;
				break;
			//Cruz
			default:
				this.form.reset();
				this.isVisibleModalChangePassword = false;
		}
	}

	closeDialogModalOKChange(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				this.isVisibleModalOKChange = false;
				break;
			//Cruz
			default:
				this.isVisibleModalOKChange = false;
		}
	}
}
