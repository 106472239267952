import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IFilter, ISelect, ITableHeader, ITableRow } from '@tecalisdev/infinity';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { Pagination } from 'src/app/core/models/pagination.model';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { CommissionsService } from './services/commissions.service';

import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Commission, Evolution } from './models/commission.model';
import { Line } from './models/line.model';
import { List } from './models/list.model';
import { GetFiltersTable, GetHeadersTable } from './utils/get-info-table.class';

@Component({
	selector: 'app-commisions',
	templateUrl: './commisions.component.html',
	styleUrls: ['./commisions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommisionsComponent implements OnInit {
	adminRole: string = this.jwt.decompileToken(this.cookieService.get('token'))?.data?.role || 'partner';
	//tipos de roles --> super_admin: el mas tocho; partner_admin: a medias entre basico y tocho; partner: el más basico
	dashBoard$: Observable<any>; //cambiar cuando se tenga la estructura de datos

	tab: number = 1;
	isVisibleDialogEdit: boolean = false;
	isVisibleDialogRemove: boolean = false;
	isVisibleDialogDownload: boolean = false;
	invoiceSubmission: string = 'pending';
	proceedingsSelected: number = 0;

	commissions: Commission = {
		total: '0',
		pending: '0',
		evolution: [],
		products: [],
	};

	typeOfProducts: any = {
		Identity: { total: '0€' },
		Signature: { total: '0€' },
		Customer_hub: { total: '0€' },
		Others: { total: '0€' },
	};

	charEvolutionData: any = {
		Identity: { name: 'Identity', type: 'bar', stack: 'total', data: [], encode: { y: 'category', x: 'value' } },
		Signature: { name: 'Signature', type: 'bar', stack: 'total', data: [], encode: { y: 'category', x: 'value' } },
		Customer_hub: { name: 'Customer_hub', type: 'bar', stack: 'total', data: [], encode: { y: 'category', x: 'value' } },
		Others: { name: 'Others', type: 'bar', stack: 'total', data: [], encode: { y: 'category', x: 'value' } },
	};

	monthsEvolutionData: Array<string> = [];
	chartDataSeries: Array<Object> = [];

	dataEditModalSettlementRequest: any = { clickValue: '0', cells: [] };

	filtersClients: IFilter[];
	filtersSettlement: IFilter[];
	filtersSettlementRequest: IFilter[];
	filtersProceedings: IFilter[] = this.getFiltersTable.filtersProceedings;

	partners: Array<string> = [];
	filterPartners: ISelect[] = [];
	tableHeadersClients: ITableHeader[] = this.getHeaders.TableHeadersClients;
	tableHeadersSettlementRequest: ITableHeader[] = this.getHeaders.TableHeadersSettlementRequest;
	tableHeadersProceedings: ITableHeader[] = this.getHeaders.TableHeadersProceedings;
	tableHeadersSettlements: ITableHeader[] = this.getHeaders.tableHeadersSettlements;

	tableRowsClients: ITableRow[] = [];
	tableRowsSettlementRequest: ITableRow[] = [];
	tableRowsProceedings: ITableRow[] = [];

	tableRowsSettlements: ITableRow[] = [];

	pagination: Pagination = {
		page: 1,
		limit: 5,
		total: 11,
		label: this.translate.instant('PAGINATION.PAGES_ELEMENTS'),
		elementsByPage: [
			{ id: '5', label: '5' },
			{ id: '10', label: '10' },
			{ id: '25', label: '25' },
		],
	};

	params: HttpParams;
	constructor(
		private commissionsService: CommissionsService,
		private readonly change: ChangeDetectorRef,
		private translate: TranslateService,
		private jwt: JwtService,
		private cookieService: CookieService,
		private getHeaders: GetHeadersTable,
		private getFiltersTable: GetFiltersTable,
		private currencyPipe: CurrencyPipe,
		private datePipe: DatePipe,
		private titleCasePipe: TitleCasePipe,
		private spinnerService: SpinnerService
	) {}

	ngOnInit(): void {
		this.dashBoard$ = this.getCommissions({
			page: this.pagination.page,
			limit: this.pagination.limit,
		});

		// this.spinnerService.show();
	}

	getCommissions({ page, limit }: { page: number; limit: number }, params?: HttpParams): Observable<Commission> {
		return this.commissionsService.getCommissions({ page, limit }, params).pipe(
			tap((commissions: any) => {
				this.pagination.total = commissions.total;
				this.commissions.total = this.currencyPipe.transform(commissions.comissions.total.replace(',', '.'), 'EUR') || '0€';
				this.commissions.pending =
					this.currencyPipe.transform(commissions.comissions.pending.replace(',', '.'), 'EUR') || '0€';

				commissions.comissions.products.reduce((product: any, element: any) => {
					product[element.name] = this.typeOfProducts[element.name] = {
						code: element.code,
						total: this.currencyPipe.transform(element.total.replace(',', '.'), 'EUR') || '0€',
					};
					return product;
				}, {});

				commissions.comissions.evolution.forEach((evolution: Evolution) => {
					this.monthsEvolutionData.push(evolution.month);
					this.fillChartDataSeries(evolution);
				});

				this.chartDataSeries.push(
					this.charEvolutionData.Identity,
					this.charEvolutionData.Signature,
					this.charEvolutionData.Customer_hub,
					this.charEvolutionData.Others
				);

				if (this.adminRole === 'super_admin') {
					this.fillTableRowsSettlements(commissions.list);

					this.filterPartners = this.partners.map((partner) => {
						return {
							id: partner,
							label: partner,
						};
					});

					this.filtersSettlement = this.getFiltersTable.filtersSettlement(commissions.periods, commissions.status);
				}

				if (this.adminRole === 'partner_admin') {
					this.fillTableRowsSettlementsRequest(commissions.list);
					this.filtersSettlementRequest = this.getFiltersTable.filtersSettlement(commissions.periods, commissions.status);

					this.fillTableRowsProcessing(commissions.lines);
				}

				if (this.adminRole === 'partner') {
					this.fillTableRowsClients(commissions.list);
					this.filtersClients = this.getFiltersTable.filtersClients(commissions.comissions.products);
				}

				this.change.detectChanges();
			})
		);
	}

	fillChartDataSeries(evolution: Evolution) {
		evolution.comissions.forEach((comission: any) => {
			if (typeof comission?.total === 'string')
				this.charEvolutionData[comission.name].data.push(parseFloat(comission.total.replace(',', '.')));
			else this.charEvolutionData[comission.name].data.push(comission.total);
		});
	}

	fillTableRowsClients(list: List[]) {
		list.forEach((l, index) => {
			this.tableRowsClients.push({
				clickValue: `${index + 1}`,
				cells: [
					{ label: l.name || '-' },
					{
						label: '',
						tag: l.products?.map((product) => {
							return {
								label: product.name,
								status: 'default',
							};
						}),
					},
					{ label: l.contracts || '0', badge: { type: 'square', label: '' } },
					{ align: 'right', label: this.currencyPipe.transform(l.comission?.replace(',', '.'), 'EUR') || '0€' },
				],
			});
		});
	}

	fillTableRowsProcessing(lines: Line[]) {
		lines.forEach((line, index) => {
			this.tableRowsProceedings.push({
				clickValue: `${index + 1}`,
				cells: [
					{
						label: line.id,
					},
					{ label: line.name },
					{ label: line.period },
					{ label: this.currencyPipe.transform(line.amount, 'EUR') || '0€' },
					{ label: line.date?.toString() || '-' },
				],
			});
		});
	}

	fillTableRowsSettlementsRequest(list: List[]) {
		list.forEach((l, index) => {
			this.tableRowsSettlementRequest.push({
				clickValue: `${index + 1}`,
				cells: [
					{
						label: l.id,
						tagImage: { icon: 'icon-file', status: 'notice' },
					},
					{
						label: '',
						tag: {
							label: this.translate.instant(
								l.status === 'pending'
									? 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.PENDING'
									: l.status === 'sent'
									? 'COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.SENT'
									: l.status === 'pending_paid'
									? 'COMMISSIONS.SETTLEMENTS_TABLE.PENDING_PAYMENT'
									: '-'
							),
							status: l.status === 'pending' ? 'warning' : 'success',
						},
					}, //revisar con Mario si hay mas casos
					{ label: l.lines, badge: { type: 'square', label: '' } },
					{ label: this.titleCasePipe.transform(this.datePipe.transform(new Date(l.period), 'MMMM y')) || '-' },
					{ label: this.currencyPipe.transform(l.amount.replace(',', '.'), 'EUR') || '0€' },
					{ label: l.created_at },
					{
						label: '',
						align: 'right',
						buttonIcon: {
							id: 'button2dropdown',
							icon: 'icon-more',
							menu: [
								{ label: 'Descargar PDF', icon: 'icon-pdf', value: true, id: 'download' },
								{ label: 'Editar', icon: 'icon-edit', id: 'edit' },
								{ label: 'Eliminar', icon: 'icon-trash', id: 'delete', divider: true, style: 'red' },
							],
						},
					},
				],
			});
		});
	}

	fillTableRowsSettlements(list: List[]) {
		list.forEach((l, index) => {
			if (!this.partners.includes(l.partner)) this.partners.push(l.partner);
			this.tableRowsSettlements.push({
				clickValue: `${index + 1}`,
				cells: [
					{
						label: l.id,
						tagImage: { icon: 'icon-file', status: 'notice' },
					},
					{ label: l.partner },
					{ label: l.lines },
					{ label: this.datePipe.transform(new Date(l.period), 'dd/MM/yyyy') || '-' },
					{ label: this.currencyPipe.transform(l.amount.replace(',', '.'), 'EUR') || '0€' },
					{
						label: '',
						tag: {
							label: this.translate.instant(
								l.status === 'pending'
									? 'COMMISSIONS.SETTLEMENTS_TABLE.PENDING_REVIEW'
									: l.status === 'sent'
									? 'COMMISSIONS.SETTLEMENTS_TABLE.PAID'
									: l.status === 'pending_paid'
									? 'COMMISSIONS.SETTLEMENTS_TABLE.PENDING_PAYMENT'
									: '-'
							),
							status:
								l.status === 'pending'
									? 'default'
									: l.status === 'sent'
									? 'success'
									: l.status === 'pending_paid'
									? 'warning'
									: 'default',
						},
					},
					{ label: l.created_at },
				],
			});
		});
	}

	getFilters(filters: any, type: string) {
		this.params = new HttpParams()
			// .set('company', filters.company?.id ?? '')
			// .set('role', filters.role?.id ?? '')
			// .set('owner', filters.owner?.id ?? '')
			.set('name', filters.name);
		// if (filters.fromDate) this.params = this.params.set('fromDate', new Date(filters.fromDate).toISOString());
		// if (filters.untilDate) this.params = this.params.set('untilDate', new Date(filters.untilDate).toISOString());
		this.pagination.page = 1
		this.getCommissions(
			{
				page: this.pagination.page,
				limit: this.pagination.limit,
			},
			this.params
		);
	}

	settlementRequestAction(action: string) {
		this.isVisibleDialogDownload = true;
	}

	tabSelected(event: any) {
		this.tab = parseInt(event?.id) || this.tab;
	}

	tableRowClick(event: any) {
		// const contact = this.data.find((contact) => contact.id === event.clickValue)!;
		// this.contactService.setSelectedContact(contact);
		// this.router.navigate(['sales/contacts/details', contact.id]);
	}

	paginationChange(event: any) {

		// this.dashBoard$ = this.getCommissions(
		// 	{
		// 		page: this.pagination.page,
		// 		limit: this.pagination.limit,
		// 	},
		// 	this.params
		// );
		// this.getCommissions(
		// 	{
		// 		page: event.page,
		// 		limit: event.limit,
		// 	},
		// 	this.params
		// );
	}

	closeDialogModalDelete(event: any) {
		switch (event) {
			//Eliminar
			case 'buttonPrimary':
				break;
			//Cancelar
			case 'buttonSecondary':
				this.isVisibleDialogRemove = false;
				break;
			//Cruz
			default:
				this.isVisibleDialogRemove = false;
		}
	}

	closeDialogModalEdit(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				break;
			//Cancelar
			case 'buttonSecondary':
				this.isVisibleDialogEdit = false;
				break;
			//Cruz
			default:
				this.isVisibleDialogEdit = false;
		}
	}

	closeDialogModalDownload(event: any) {
		this.isVisibleDialogDownload = false;
	}

	clickEvent(event: any) {}

	selectedOptionChange(event: any) {
	}

	test(event: any) {
		this.proceedingsSelected = event?.ids?.length | 0;
	}

	cellClickSelection(event: any) {
		const { cellId, rowId } = event;

		switch (cellId.id) {
			case 'download':
				break;
			case 'edit':
				this.dataEditModalSettlementRequest =
					this.tableRowsSettlementRequest.find((row) => {
						return row.clickValue === rowId;
					}) || this.dataEditModalSettlementRequest;

				this.isVisibleDialogEdit = true;
				break;
			case 'delete':
				this.isVisibleDialogRemove = true;
				break;
		}
	}

	rowClickSelection(event: any) {
	}
}
