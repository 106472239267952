import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProfileUserService {
	constructor(private http: HttpClient) {}

	getProfileInfo() {
		return this.http.get<Profile>(`${environment.apiUrl}/profile`);
	}

	setProfileInfo(body: FormData) {
		return this.http.post<any>(`${environment.apiUrl}/profile`, body);
	}

	changePassword(password: string): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/password/change`, { password });
	}
}
